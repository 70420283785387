import { ValidationErrorSeverity, type ValidationResult } from "@component-utils/validations";
import { computed, ref, watchEffect, type Ref } from "vue";
import type { LengthRestrictionProps, ValidationProps } from "./types";
import { globalLocalize, useLocalize } from "@component-utils/localization";
import Utils from "~/features/utils";
import { useErrorToast } from "@component-utils/toasts";
import PasswordsApi from "~/api/Users/PasswordsApi";
import useDebounce from "~/features/_abstract/utils/debounce";
import { resolveAccept, toContentTypes, type Accept } from "@component-utils/files";

type CreateDefaultValidatorResult<T> = (value: T | null | undefined) => ValidationResult

export function composeValidations<T>(props: ValidationProps<T>, defaultValidator?: CreateDefaultValidatorResult<T>) {
  if (!defaultValidator || !props.validator) return props.validator ?? defaultValidator

  if (props.validatorMode === 'force') {
    // Force only user validation
    return props.validator
  } else {
    // Merge validations together
    return (value: T | null | undefined) => props.validator!(value) ?? defaultValidator(value)
  }
}

export function validationError(message: string): ValidationResult {
  return [ValidationErrorSeverity.Error, message] 
}

export function validationWarning(message: string): ValidationResult {
  return [ValidationErrorSeverity.Warning, message]
}

export function useValidation<T>(
  validatorSubject: Ref<undefined | null | T>,
  props: ValidationProps<T>,
  defaultValidator?: CreateDefaultValidatorResult<T>
) {
  const validator = composeValidations(props, defaultValidator)

  const validationVisible = ref(props.validationTrigger === 'immediate')

  const validationResult = computed(() => validator && validator(validatorSubject.value) || null)
  const isValid = computed(() => !validationResult.value)

  const validationResultForDisplay = computed(() => validationVisible.value && validationResult.value || null)
  const isValidationError = computed(() => validationResultForDisplay.value?.[0] === ValidationErrorSeverity.Error)
  const isValidationWarning = computed(() => validationResultForDisplay.value?.[0] === ValidationErrorSeverity.Warning)

  return {
    // For display
    validationVisible,
    validationResult: validationResultForDisplay,
    isValidationError,
    isValidationWarning,
    // For expose, regardless of visibility
    isValid
  }
}

export function useComponentValidation(...refs: Ref<{ isValid: boolean }[] | { isValid: boolean } | null>[]) {
  return computed(() => refs.every((ref) => {
    const value = ref.value
    if (value) {
      if (Array.isArray(value)) {
        return value.every((item) => item.isValid)
      } else {
        return value.isValid
      }
    } else {
      return true
    }
  }))
}

export function isValidationError(validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Error
}

export function isValidationWarning(validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Warning
}

export function usePasswordRequirementsValidation(modelValue: Ref<string | null | undefined>) {
  const localize = useLocalize('component-library.validations.password.requirements')

  type ValidationDefinition = { type: string } & ({
    instant: true, method: (password: string) => boolean
  } | {
    instant: false, method: (password: string) => Promise<boolean>
  })

  const validationMethods: ValidationDefinition[] = [
    {
      type: 'min_length',
      instant: true,
      method: (password) => password.length >= 10
    },
    {
      type: 'lowercase',
      instant: true,
      method: (password) => /[a-z]/.test(password)
    },
    {
      type: 'uppercase',
      instant: true,
      method: (password) => /[A-Z]/.test(password)
    },
    {
      type: 'number',
      instant: true,
      method: (password) => /[0-9]/.test(password)
    },
    {
      type: 'special_char',
      instant: true,
      method: (password) => /[!@#$%^&*(),.?":{}|<>_]/.test(password)
    },
    {
      type: 'not_blacklisted',
      instant: false,
      method: async (password) => {
        if (!password) {
          return false
        }

        try {
          const { valid } = await PasswordsApi.validatePassword<{ valid: boolean }>({ data: { password } })

          return valid
        } catch (e) {
          useErrorToast(e)

          return false
        }
      }
    }
  ]

  const debouncedValidationMethods = validationMethods.map((entry, index) => ({
    ...entry,
    setDebounced: entry.instant ? undefined : useDebounce(async () => {
      state.value[index].isValid = await entry.method((modelValue.value || '').trim())
    }, 200)
  }))

  const state = ref(validationMethods.map(({ type }) => ({
    isValid: false,
    text: localize(type)
  })))

  watchEffect(() => {
    const password = (modelValue.value || '').trim()
    const passwordState = state.value

    for (let i = 0; i < debouncedValidationMethods.length; i++) {
      const { method, setDebounced, instant } = debouncedValidationMethods[i]

      if (instant) {
        passwordState[i].isValid = method(password)
      } else {
        // Clear isValid so entry doesnt show as valid while we are waiting for request to finish
        passwordState[i].isValid = false

        void setDebounced!()
      }
    }
  })

  return {
    requirements: state,
    requirementsValid: computed(() => state.value.every((requirement) => requirement.isValid))
  }
}

export function validatePasswordRequirements(rawPassword: string | null | undefined) {
  const password = (rawPassword || '').trim()
  const localize = useLocalize('component-library.validations.password.requirements')

  return [
    {
      text: localize('min_length'),
      isValid: password.length >= 10
    },
    {
      text: localize('lowercase'),
      isValid: /[a-z]/.test(password)
    },
    {
      text: localize('uppercase'),
      isValid: /[A-Z]/.test(password)
    },
    {
      text: localize('number'),
      isValid: /[0-9]/.test(password)
    },
    {
      text: localize('special_char'),
      isValid: /[!@#$%^&*(),.?":{}|<>_]/.test(password)
    }
  ]
}

export function createDefaultFileValidator(props: { required?: boolean, accept: Accept }): CreateDefaultValidatorResult<Array<File | Backend.Types.AttachedFile>> {
  return (value: Array<File | Backend.Types.AttachedFile> | undefined | null) => {
    if (!Array.isArray(value) || value.length === 0) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.generic.empty'))
      }

      return null
    }

    const validContentTypes = toContentTypes(resolveAccept(props.accept))

    for (const file of (value)) {
      // For now ignore already attached file
      if ('path' in file) continue

      if (file.size >= 256e6) {
        return validationError(globalLocalize('component-library.validations.file.size'))
      }

      if (validContentTypes.length > 0 && !validContentTypes.includes(file.type as unknown as typeof validContentTypes[number]) && !validContentTypes.includes(file.name.slice(file.name.lastIndexOf('.')) as unknown as typeof validContentTypes[number])) {
        return validationError(globalLocalize('component-library.validations.file.type'))
      }
    }

    return null
  }
}

export function createDefaultEmailValidator(props: { required?: boolean } & LengthRestrictionProps): CreateDefaultValidatorResult<string> {
  return (value: string | undefined | null) => {
    if (!value || !value.trim()) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.email.empty'))
      }

      return null
    }

    if (!Utils.isEmailValid(value)) {
      return validationError(globalLocalize('component-library.validations.email.format'))
    }

    const result = validateValueLength(value, props)
    if (result) {
      return result
    }

    return null
  }
}

export function createDefaultPasswordValidator(props: { required?: boolean, showRequirements?: boolean } & LengthRestrictionProps): CreateDefaultValidatorResult<string> {
  return (value: string | undefined | null) => {
    if (!value || !value.trim()) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.password.empty'))
      }

      return null
    }

    if (!props.showRequirements && value.trim().length < 10) {
      return validationError(globalLocalize('component-library.validations.password.format'))
    }

    const result = validateValueLength(value, props)
    if (result) {
      return result
    }

    return null
  }
}

export function createDefaultMultiSelectValidator<T>(props: { required?: boolean }): CreateDefaultValidatorResult<T[]> {
  return (value: T[] | undefined | null) => {
    if (!value || value.length === 0) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.generic.empty'))
      }

      return null
    }

    return null
  }
}

export function createDefaultOTPValidator(props: { required?: boolean }): CreateDefaultValidatorResult<string> {
  return (value: string | undefined | null) => {
    if (!value || !value.trim()) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.generic.empty'))
      }

      return null
    }

    if (value.length < 6) {
      return validationError(globalLocalize('component-library.validations.otp.format'))
    }

    return null
  }
}

function validateValueLength (value: unknown, props: LengthRestrictionProps) {
  if (typeof props.minLength === 'number' && String(value).length < props.minLength) {
    return validationError(globalLocalize('component-library.validations.generic.length_min', { min: props.minLength }))
  } else if (typeof props.maxLength === 'number' && String(value).length > props.maxLength) {
    return validationError(globalLocalize('component-library.validations.generic.length_max', { max: props.maxLength }))
  } else {
    return null
  }
}

export function createDefaultNumberValidator(props: { required?: boolean, min?: number, max?: number } & LengthRestrictionProps): CreateDefaultValidatorResult<number> {
  return (value: number | undefined | null) => {
    if (!value) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.generic.empty'))
      }

      return null
    }

    if (typeof props.min === 'number' && value < props.min) {
      return validationError(globalLocalize('component-library.validations.number.min', { min: props.min }))
    } else if (typeof props.max === 'number' && value > props.max) {
      return validationError(globalLocalize('component-library.validations.number.max', { max: props.max }))
    }

    const result = validateValueLength(value, props)
    if (result) {
      return result
    }

    return null
  }
}

export function createDefaultValidator<T>(props: { required?: boolean } & LengthRestrictionProps): CreateDefaultValidatorResult<T> {
  return (value: T | undefined | null) => {
    if (!value || (typeof value === 'string' && !value.trim())) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.generic.empty'))
      }

      return null
    }

    const result = validateValueLength(value, props)
    if (result) {
      return result
    }

    return null
  }
}

export function createDefaultURLValidator (props: { required?: boolean, domainOnly?: boolean } & LengthRestrictionProps): CreateDefaultValidatorResult<string>{
  return (value: string | undefined | null) => {
    if (!value || !value.trim()) {
      if (props.required) {
        return validationError(globalLocalize('component-library.validations.generic.empty'))
      }

      return null
    }

    const result = validateValueLength(value, props)
    if (result) {
      return result
    }

    try {
      if (props.domainOnly) {
        if (value.startsWith("http://") || value.startsWith("https://")) {
          return validationError(globalLocalize('component-library.validations.url.domain_protocol'))
        }

        value = `https://${value}`
      }
      
      if (value.includes(" ")) {
        return validationError(globalLocalize('component-library.validations.url.spaces'))
      }

      if (!value.startsWith('http://') && !value.startsWith('https://')) {
        return validationError(globalLocalize('component-library.validations.url.protocol'))
      }
      
      if (value.length > 2048) {
        return validationError(globalLocalize('component-library.validations.url.too_long'))
      }

      const urlObject = new URL(value)
      const urlParts = urlObject.hostname.split(".")

      /** URL needs both domain nad TLD */
      if (urlParts.length < 2) {
        return validationError(globalLocalize('component-library.validations.url.domain_and_tld'))
      }

      /** domain name length validation */
      if (urlParts[0].length === 0) {
        return validationError(globalLocalize('component-library.validations.url.domain'))
      }

      /** TLD must have at least 2 characters  */
      if (urlParts[1].length < 2) {
        return validationError(globalLocalize('component-library.validations.url.invalid_tld'))
      }
    } catch {
      return validationError(globalLocalize('component-library.validations.url.invalid'))
    }


    return null
  }
}