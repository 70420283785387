import { getObjectEntries, Key } from "@avvoka/shared"

export const enum ValidationErrorSeverity {
  Warning = 'warning',
  Error = 'error'
}

type ValidationError = [ValidationErrorSeverity, string]

export type ValidationResult = ValidationError | undefined | null

type ValidationFn<TValue> = (value: TValue) => ValidationResult

type OneOrArray<T> = T | T[]

type Validation<TValue> = ValidationFn<TValue>

export type Validator<TValidatedObject> = {
  isValid: (object: TValidatedObject) => boolean,
  isInvalid: (object: TValidatedObject) => boolean
} & ComposedValidations<TValidatedObject, keyof TValidatedObject>

type MemberValidationsNested<TValidatedObject, TValidatedKeys extends keyof TValidatedObject> = {
  [TKey in TValidatedKeys]?: (
    TValidatedObject[TKey] extends Array<unknown> ? (
      OneOrArray<MemberValidations<TValidatedObject[TKey][number], keyof TValidatedObject[TKey][number]> | Validation<TValidatedObject[TKey]>>
    ) : (
      TValidatedObject[TKey] extends object ? (
        OneOrArray<MemberValidations<TValidatedObject[TKey], keyof TValidatedObject[TKey]> | Validation<TValidatedObject[TKey]>>
      ) : (
        OneOrArray<Validation<TValidatedObject[TKey]>>
      )
    )
  )
}

type MemberValidations<TValidatedObject, TValidatedKeys extends keyof TValidatedObject> = OneOrArray<Validation<TValidatedObject> | MemberValidationsNested<TValidatedObject, TValidatedKeys>>

type ComposedValidations<TValidatedObject, TValidatedKeys extends keyof TValidatedObject> = {
  [TKey in TValidatedKeys]: (
    TValidatedObject[TKey] extends Array<unknown> ? (
      ComposedValidations<TValidatedObject[TKey][number], keyof TValidatedObject[TKey][number]> | ValidationFn<TValidatedObject[TKey]>
    ) : (
      TValidatedObject[TKey] extends object ? (
        ComposedValidations<TValidatedObject[TKey], keyof TValidatedObject[TKey]> | ValidationFn<TValidatedObject[TKey]>
      ) : (
        ValidationFn<TValidatedObject[TKey]>
      )
    )
  )
} & {
  __self: ValidationFn<TValidatedObject>
}

type ValidationPath = (string | number | symbol)[]
type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][]

export const createValidator = function <const TValidatedObject extends object> () {
  return function <const TValidatedKeys extends keyof TValidatedObject>(memberValidations: MemberValidations<TValidatedObject, TValidatedKeys>) {
    function composeValidations <const T extends object, const TKeys extends keyof T, const TValidatedObjectType extends 'root'>(memberValidationsRaw: MemberValidations<T, TKeys>, type: TValidatedObjectType): ComposedValidations<T, TKeys>
    function composeValidations <const T extends object, const TKeys extends keyof T, const TValidatedObjectType extends 'property'>(memberValidationsRaw: MemberValidations<T, TKeys>, type: TValidatedObjectType): ValidationFn<T> | ComposedValidations<T, TKeys>
    function composeValidations <const T extends object, const TKeys extends keyof T, const TValidatedObjectType extends 'root' | 'property'>(memberValidationsRaw: MemberValidations<T, TKeys>, type: TValidatedObjectType) {
      const memberValidations = (Array.isArray(memberValidationsRaw) ? memberValidationsRaw : [memberValidationsRaw]) as (MemberValidations<T, TKeys> | Validation<T[TKeys]>)[]

      const memberValidationDirect = memberValidations.filter((validation) => typeof validation !== 'object') as Validation<T>[]
      const memberValidationNested = memberValidations.filter((validation) => typeof validation === 'object') as MemberValidationsNested<T, TKeys>[]

      const memberValidationMethod = (value: T) => memberValidationDirect.reduce((memo: ValidationResult, validation: Validation<T>) => {
        return memo || validation(value)
      }, undefined)

      if (type === 'root' || memberValidationNested.length > 0) {
        const object = {
          __self: memberValidationMethod
        } as ComposedValidations<T, TKeys>

        for (const validation of memberValidationNested) {
          const entries = getObjectEntries(validation) as [TKeys, MemberValidations<T, TKeys>][]
          const entriesMapped = entries.map(([property, validation]) => [property, composeValidations(validation, 'property')])

          Object.assign(
            object,
            Object.fromEntries(
              entriesMapped
            )
          )
        }

        return object
      } else {
        return memberValidationMethod
      }
    }
  
    const composedValidations = composeValidations<TValidatedObject, TValidatedKeys, 'root'>(memberValidations, 'root')

    const validate = <const T extends object, const TKeys extends keyof T>(object: T, validations: ComposedValidations<T, TKeys>, path: ValidationPath = []) => {
      const results: [ValidationPath, ...ValidationError][] = []

      for (const [member, memberValidation] of getObjectEntries(validations) as Entries<typeof validations>) {
        if (member === '__self') {
          const result = memberValidation(object) as ValidationResult
          if (result) {
            results.push([path, ...result])
          }
        } else {
          const value = object[member]
  
          if (typeof memberValidation === 'function') {
            const result = memberValidation(value) as ValidationResult
            if (result) {
              results.push([[...path, member], ...result])
            }
          } else if (value && typeof value === 'object') {
            if ('__self' in memberValidation) {
              const result = memberValidation['__self'](value)
              if (result) {
                results.push([[...path, member], ...result])
              }
            }

            const isArray = Array.isArray(value)
    
            if (isArray) {
              for (let i = 0; i < value.length; i++) {
                results.push(...validate(value[i], memberValidation, [...path, member, i]))
              }
            } else {
              results.push(...validate(value, memberValidation, [...path, member]))
            }
          }
        }
      }
  
      return results
    }
  
    return {
      // Mask composed validations
      validate: (object: TValidatedObject) => validate(object, composedValidations),
      // Shortcut methods
      isValid: (object: TValidatedObject) => validate(object, composedValidations).length === 0,
      isInvalid: (object: TValidatedObject) => validate(object, composedValidations).length > 0,
      // Member validations
      ...composedValidations
    } as const
  }
}

const NUMBER_LIKE_CHARACTERS = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.'
]

const CONTROL_LIKE_CHARACTERS = [
  Key.Backspace, Key.Delete, Key.ArrowUp, Key.ArrowDown, Key.ArrowLeft, Key.Tab,
  Key.ArrowRight, Key.Enter, Key.NumpadEnter, Key.ShiftLeft, Key.ShiftRight, Key.Escape,
  Key.F1, Key.F2, Key.F3, Key.F4, Key.F5, Key.F6, Key.F7, Key.F8, Key.F9, Key.F10, Key.F11, Key.F12
]

export function isNullOrUndefined<T>(value: T | undefined | null): value is undefined | null {
  return typeof value === 'undefined' || value === null
}

export function isNumberLikeCharacter(key: string | Key) {
  return NUMBER_LIKE_CHARACTERS.includes(key)
}

export function isControlCharacter(key: string | Key) {
  return CONTROL_LIKE_CHARACTERS.includes(key as Key)
}
