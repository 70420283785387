// JsFromRoutes CacheKey abb57deda6bc552f64f2e150f979b8ba
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/users/password/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/users/password/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/users/password'),
  create: /* #__PURE__ */ definePathHelper('post', '/users/password'),
  validatePassword: /* #__PURE__ */ definePathHelper('post', '/users/passwords/validate_password'),
}
