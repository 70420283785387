<template>
  <div :class="props.container ?? 'contents'" data-component-name="VSResetButton">
    <VButton v-if="props.allowEmpty && !(props.disabled || props.readonly)" raw @click.stop.prevent="emit('click')">
      <VIcon size="sm" name="Solid/x-mark" />
    </VButton>
  </div>
</template>
<script setup lang="ts">
import VButton from '@component-library/buttons/VButton.vue';
import VIcon from '@component-library/labels/VIcon.vue';

defineOptions({
  name: 'VSResetButton',
  inheritAttrs: false
})

const emit = defineEmits<{
  click: []
}>()

const props = defineProps<{
  allowEmpty?: boolean
  disabled?: boolean
  readonly?: boolean
  container?: string
}>()
</script> 